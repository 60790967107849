
import { setPageHeader } from "@/core/helpers/toolbar";
import { defineComponent, onMounted } from "vue";
import AddCategory from "@/modules/catalog/categories/components/AddCategory.vue";
import EditCategory from "@/modules/catalog/categories/components/EditCategory.vue";
import CategoriesList from "@/modules/catalog/categories/components/CategoriesList.vue";

export default defineComponent({
	name: "categories",
	components: {
		AddCategory,
		CategoriesList,
		EditCategory,
	},
	setup() {
		onMounted(() => {
			setPageHeader({
				title: "message.CATEGORIES",
				actionButton: {
					ability: "add_category",
					pageAction: {
						action: "addCategoryAction",
					},
					button: {
						icon: "bi bi-plus",
						openModal: true,
					},
				},
				breadCrumbs: [{ name: "message.HOME", link: "/dashboard" }, { name: "message.CATALOG", link: "#" }, { name: "message.CATEGORIES" }],
			});
		});
	},
});
