import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CategoriesList = _resolveComponent("CategoriesList")!
  const _component_AddCategory = _resolveComponent("AddCategory")!
  const _component_EditCategory = _resolveComponent("EditCategory")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", null, [
      _createVNode(_component_CategoriesList)
    ]),
    _createVNode(_component_AddCategory),
    _createVNode(_component_EditCategory)
  ], 64))
}