import { gql } from "graphql-tag";
import { CATEGORY_FIELDS } from "./Fragments";

export const CREATE_CATEGORY = gql`
	mutation CreateCategory($input: CategoryInput!) {
		create_category(category: $input) {
			...CategoryFields
		}
	}
	${CATEGORY_FIELDS}
`;

export const UPDATE_CATEGORY = gql`
	mutation UpdateCategory($input: CategoryInput!) {
		update_category(category: $input) {
			...CategoryFields
		}
	}
	${CATEGORY_FIELDS}
`;

export const UPDATE_CATEGORY_POSITION = gql`
	mutation UpdateCategoryPosition($input: UpdateCategoryPositionInput!) {
		update_category_position(positions: $input)
	}
`;

export const DELETE_CATEGORY = gql`
	mutation DeleteCategory($id: Int!) {
		delete_category(id: $id) {
			...CategoryFields
		}
	}
	${CATEGORY_FIELDS}
`;

export default { CREATE_CATEGORY, UPDATE_CATEGORY, UPDATE_CATEGORY_POSITION, DELETE_CATEGORY };
