
import { Modal } from 'bootstrap';
import { defineComponent, ref, inject, computed, watchEffect } from 'vue';
import { Input, Select, Image } from '@/components/input-elements';
import { useStore } from 'vuex';
import { Apollo, Notify } from '@/core/services';
import {GET_CATEGORIES, GET_CATEGORIES_DROPDOWN_LIST} from '../graphql/Queries';
import { useI18n } from 'vue-i18n';
import { UPDATE_CATEGORY } from '@/modules/catalog/categories/graphql/Mutations';
import { CatalogEditFormData } from '@/modules/catalog/categories/interfaces';
import { Actions } from '@/store/enums/StoreEnums';
import { GET_LOCALES } from '@/modules/common/locales/graphql/Queries';
import InnerLoader from '../../../../components/InnerLoader.vue';

export default defineComponent({
    name: 'edit category',
    components: {
        Input,
        Select,
        Image,
        InnerLoader
    },
    setup() {
        const emitter: any = inject('emitter');
        const current_page = ref(1);
        const loading = ref(false);
        const locales: any = ref([]);
        const modal: any = ref();
        const tabsValue = ref('da');
        const store = useStore();
        const disabled = ref(false);
        const set_sites = ref([]) as Record<any, any>;
        const editorForm = ref<null | HTMLFormElement>(null);
        const editorMetaForm = ref<null | HTMLFormElement>(null);
        const editUploadImage = ref<null | HTMLFormElement>(null);
        const editCategoryForm = ref<null | HTMLFormElement>(null);
        const categories = ref([]) as Record<any, any>;
        const i18n = useI18n();
        const image = ref([]) as Record<any, any>;
        const previewImage = ref([]) as Record<any, any>;
        const validate = ref(false);

        const editCategoryData = ref<CatalogEditFormData>({
            id: null,
            locale: 'da',
            name: '',
            sites: [],
            description: '',
            metaTitle: '',
            metaDescription: '',
            parent_id: '',
            status: false
        });

        // Load all store
        const get_sites = computed(() => {
            return store.getters.getAllSites.data.sites;
        });

        const categoriesList = ref([]) as Record<any, any>;

        // Load all categories
        const get_categories = () => {
            const sub = Apollo.watchQuery({
                query: GET_CATEGORIES_DROPDOWN_LIST,
                errorPolicy: 'all',
                fetchPolicy: 'cache-first',
                nextFetchPolicy: 'cache-first'
            }).subscribe(({ data }) => {
                categoriesList.value = [];
                data.categories.data.forEach(ele => {
                    if (editCategoryData.value.id != ele.id) {
                        categoriesList.value.push(ele);
                    }
                });
                sub.unsubscribe();
            });
        };

        // any changing in site and categories
        watchEffect(() => {
            set_sites.value = [];
            get_sites.value.forEach(element => {
                set_sites.value.push({
                    value: element.id,
                    label: element.name
                });
            });

            categories.value = [];
            categoriesList.value.forEach((element: Record<any, any>) => {
                let defaultLocale = '';
                if (JSON.parse(element.name)[`${editCategoryData.value.locale}`] == undefined) {
                    defaultLocale = 'da';
                } else {
                    defaultLocale = editCategoryData.value.locale;
                }
                categories.value.push({
                    value: element.id,
                    label: JSON.parse(element.name)[`${defaultLocale}`].name
                });
            });
        });

        // On change image
        const handleChange = async (event: Record<any, any>) => {
            loading.value = true;
            image.value = [];
            previewImage.value = [];
            const media = event.target.files;
            const response = await store.dispatch(Actions.CREATE_MEDIA, media);
            const images = JSON.parse(response);
            images.forEach(element => {
                previewImage.value.push({
                    src: element.asset_path,
                    id: element.id
                });
            });
            image.value = previewImage.value;
            loading.value = false;
        };

        // Remove Media
        const removeMedia = (index: number, media_id: number) => {
            store.getters.appInstance.$messageBox
                .confirm(`${i18n.t('message.ARE_YOU_SURE_TO_DELETE_RECORD')}?`, i18n.t('message.INFO'), {
                    confirmButtonText: i18n.t('message.OK'),
                    cancelButtonText: i18n.t('message.CANCEL'),
                    type: 'info'
                })
                .then(async () => {
                    loading.value = true;
                    const resp = await store.dispatch(Actions.REMOVE_MEDIA, media_id);
                    if (resp == 'true') {
                        previewImage.value.splice(index, 1);
                        image.value.spilce(index, 1);
                        Notify.success(i18n.t('message.MEDIA_REMOVED_SUCCESSFULLY'));
                    }
                    loading.value = false;
                })
                .catch(() => {
                    loading.value = false;
                });
        };

        // Show Media
        const showMedia = async (num: number) => {
            const formData = {
                previewImage: previewImage.value,
                visible: true,
                index: num
            };
            await store.dispatch(Actions.TRIGGER_ACTION, {
                formData: formData,
                type: 'imageProps'
            });
        };

        // Store name in Danish and English
        const tempValues = ref({}) as Record<any, any>;

        // Store description in Danish and English
        const tempValuesDescription = ref({}) as Record<any, any>;

        // Store meta description in Danish and English
        const tempValuesMetaDescription = ref({}) as Record<any, any>;
        const tempValuesMetaTitle = ref({}) as Record<any, any>;
        const loadLang = () => {
            const locales_data = Apollo.readQuery({
                query: GET_LOCALES
            });
            locales_data.locales.forEach(element => {
                locales.value.push({
                    label: element.locale.charAt(0).toUpperCase() + element.locale.slice(1),
                    value: element.locale
                });

                tempValues.value[element.locale] = { name: '' };
                tempValuesDescription.value[element.locale] = { name: '' };
                tempValuesMetaDescription.value[element.locale] = { name: '' };
                tempValuesMetaTitle.value[element.locale] = { name: '' };
            });
        };

        loadLang();

        // On change input value
        const changeLang = (e: string) => {
            tabsValue.value = e;
            editCategoryData.value.name = tempValues.value[e].name;
            editCategoryData.value.description = tempValuesDescription.value[e].name;
            editorForm.value?.setHTML(editCategoryData.value.description);
            editCategoryData.value.metaDescription = tempValuesMetaDescription.value[e].name;
            editCategoryData.value.metaTitle = tempValuesMetaTitle.value[e].name;
            editorMetaForm.value?.setHTML(editCategoryData.value.metaDescription);
        };

        // update on change value input
        const updateValue = (e: string) => {
            tempValues.value[editCategoryData.value.locale].name = e;
        };

        // tab link press to ge locale
        const tabClickHandler = (tab: Record<any, any>) => {
            tabsValue.value = tab.props.name;

            editCategoryData.value.description = tempValuesDescription.value[tab.props.name].name;
            editorForm.value?.setHTML(editCategoryData.value.description);

            editCategoryData.value.metaDescription = tempValuesMetaDescription.value[tab.props.name].name;
            editCategoryData.value.metaTitle = tempValuesMetaTitle.value[tab.props.name].name;
            editorMetaForm.value?.setHTML(editCategoryData.value.metaDescription);

            editCategoryData.value.locale = tab.props.name;
            editCategoryData.value.name = tempValues.value[tab.props.name].name;
        };

        // Update value on change description
        const updateValueDescription = (e: string) => {
            tempValuesDescription.value[editCategoryData.value.locale].name = e;
        };

        // Update value on change meta description
        const updateValueMetaDescription = (e: string) => {
            tempValuesMetaDescription.value[editCategoryData.value.locale].name = e;
        };

        // Update value on change meta title
        const updateValueMetaTitle = (e: string) => {
            tempValuesMetaTitle.value[editCategoryData.value.locale].name = e;
        };

        const customValidations = () => {
            if ((editCategoryData.value.description == '' && validate.value == true) || editCategoryData.value.description === '<p><br></p>') {
                Notify.error(`${i18n.t('message.CATEGORY')} ${i18n.t('message.DESCRIPTION')} ${i18n.t('message.IS_REQUIRED')}`);
                return false;
            }

            return true;
        };

        // Form submit Handler
        const submitUserForm = () => {
            //
            for (const i in tempValuesDescription.value) {
                if (tempValuesDescription.value[i].name == '') {
                    tempValuesDescription.value[i].name = editCategoryData.value.description;
                }
            }

            for (const i in tempValuesMetaDescription.value) {
                if (tempValuesMetaDescription.value[i].name == '') {
                    tempValuesMetaDescription.value[i].name = editCategoryData.value.metaDescription;
                }
            }

            for (const i in tempValuesMetaTitle.value) {
                if (tempValuesMetaTitle.value[i].name == '') {
                    tempValuesMetaTitle.value[i].name = editCategoryData.value.metaTitle;
                }
            }

            for (const i in tempValues.value) {
                if (tempValues.value[i].name == '') {
                    tempValues.value[i].name = editCategoryData.value.name;
                }
            }

            validate.value = true;
            editCategoryForm.value?.validate(async (valid: boolean) => {
                if (valid) {
                    const validator = customValidations();
                    if (validator == true) {
                        const formData = {
                            id: editCategoryData.value.id,
                            name: JSON.stringify(tempValues.value),
                            description: JSON.stringify(tempValuesDescription.value),
                            meta_description: JSON.stringify(tempValuesMetaDescription.value),
                            meta_title: JSON.stringify(tempValuesMetaTitle.value),
                            parent_id: editCategoryData.value.parent_id == null || editCategoryData.value.parent_id == '' ? 0 : editCategoryData.value.parent_id,
                            sites: editCategoryData.value.sites,
                            image: previewImage.value.length != 0 ? previewImage.value[0].id : null,
                            status: editCategoryData.value.status
                        };

                        // if (previewImage.value.length == 0) {
                        // 	Notify.error(i18n.t(`${i18n.t("message.IMAGE")} ${i18n.t("message.IS_REQUIRED")}`));
                        // 	return;
                        // }

                        loading.value = true;
                        await Apollo.mutate({
                            mutation: UPDATE_CATEGORY,
                            variables: {
                                input: formData
                            },
                            update: (store, { data: { update_category } }) => {
                                const read_data = store.readQuery({
                                    query: GET_CATEGORIES,
                                    variables: {
                                        page: current_page.value,
                                        limit: 10
                                    }
                                }) as Record<any, any>;
                                store.writeQuery({
                                    query: GET_CATEGORIES,
                                    variables: {
                                        page: current_page.value,
                                        limit: 10
                                    },
                                    data: {
                                        categories: {
                                            ...read_data.categories
                                        },
                                        variables: {
                                            id: update_category.id
                                        }
                                    }
                                });

                              const read_dropdown_list_data = store.readQuery({
                                query: GET_CATEGORIES_DROPDOWN_LIST
                              }) as Record<any, any>;
                              store.writeQuery({
                                query: GET_CATEGORIES_DROPDOWN_LIST,
                                data: {
                                  categories: {
                                    ...read_dropdown_list_data.categories
                                  },
                                  variables: {
                                    id: update_category.id
                                  }
                                }
                              });

                                modal.value.hide();
                                loading.value = false;
                                Notify.success(`${i18n.t('message.RECORD_UPDATED_SUCCESSFULLY')}`);
                            }
                        }).catch(() => {
                            loading.value = false;
                        });
                    } else {
                        loading.value = false;
                    }
                }
            });
        };

        //Reset Form Data
        const reset_form = () => {
            categories.value = [];
            // current_page.value = 1;
            get_categories();
            emitter.emit('clearInput');
            previewImage.value = [];
            set_sites.value = [];
            image.value = [];
            tabsValue.value = 'da';
            editCategoryData.value.sites = [];
            editCategoryData.value.description = '';
            editCategoryData.value.metaDescription = '';
            editCategoryData.value.metaTitle = '';
            editCategoryForm.value?.resetFields();
            editUploadImage.value?.clearFiles();

            for (const i in tempValues.value) {
                tempValues.value[i].name = '';
                tempValuesDescription.value[i].name = '';
                tempValuesMetaDescription.value[i].name = '';
                tempValuesMetaTitle.value[i].name = '';
            }
        };

        // Emitter for open modal
        emitter.on('editCategoryAction', (category: Record<any, any>) => {
            editCategoryData.value.id = category.id;
            reset_form();

            // set page number
            // current_page.value = category.current_page
            // Assign Image to upload element
            if (category.image) {
                previewImage.value.push({
                    id: category.image.id,
                    src: category.image.file_path
                });
            }
            // Loaded store into select
            category.category_to_site.forEach(element => {
                editCategoryData.value.sites.push(element.id);
            });

            // JSON decode name
            const json_decode_name = JSON.parse(category.name);
            for (const i in json_decode_name) {
                i == 'da' ? (editCategoryData.value.name = json_decode_name[i].name) : '';
                tempValues.value[i].name = json_decode_name[i].name;
            }

            //JSON decode description
            const json_decode_description = JSON.parse(category.description);
            for (const i in json_decode_description) {
                if (i == 'da') {
                    editCategoryData.value.description = json_decode_description[i].name;
                    editorForm.value?.setHTML(editCategoryData.value.description);
                }
                tempValuesDescription.value[i].name = json_decode_description[i].name;
            }

            //JSON decode meta description
            if (category.meta_description != null) {
                const json_decode_meta_description = JSON.parse(category.meta_description);
                for (const i in json_decode_meta_description) {
                    if (i == 'da') {
                        editCategoryData.value.metaDescription = json_decode_meta_description[i].name;
                        editorMetaForm.value?.setHTML(editCategoryData.value.metaDescription);
                    }
                    tempValuesMetaDescription.value[i].name = json_decode_meta_description[i].name;
                }
            } else {
                editorMetaForm.value?.setHTML('');
            }

            //JSON decode meta title
            if (category.meta_title != null) {
                const json_decode_meta_title = JSON.parse(category.meta_title);
                for (const i in json_decode_meta_title) {
                    if (i == 'da') {
                        editCategoryData.value.metaTitle = json_decode_meta_title[i].name;
                        editorMetaForm.value?.setHTML(editCategoryData.value.metaTitle);
                    }
                    tempValuesMetaTitle.value[i].name = json_decode_meta_title[i].name;
                }
            } else {
                editorMetaForm.value?.setHTML('');
            }

            if (category.parent) {
                editCategoryData.value.parent_id = category.parent.id;
            } else {
                editCategoryData.value.parent_id = null;
            }
            editCategoryData.value.status = category.status == 1 ? true : false;
            modal.value = new Modal(document.getElementById('modal_edit_category'));
            modal.value.show();
        });

        return {
            get_sites,
            set_sites,
            editorForm,
            editorMetaForm,
            locales,
            editCategoryData,
            loading,
            tabsValue,
            disabled,
            editUploadImage,
            categories,
            editCategoryForm,
            previewImage,
            validate,
            showMedia,
            removeMedia,
            tabClickHandler,
            updateValueDescription,
            updateValueMetaDescription,
            handleChange,
            changeLang,
            updateValue,
            submitUserForm,
            updateValueMetaTitle
        };
    }
});
